import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import api from "@/api";
import OwnerPropertyCard from "@/components/OwnerPropertyCard.vue";
import { PropertyStatus } from "@/enums/PropertyStatus";
import { IOwnerProperty } from "@/models/OwnerProperties";

/* MOCKS */
jest.mock("@/api");
jest.mock("@/services/MyRentalsConfig");

const property: IOwnerProperty = {
  propertyId: 1,
  propertyUuid: "e8b7941a-5907-43fc-8b41-fd7761d833aew",
  location: {
    id: 1801,
    name: "Hostafrancs",
    state: { id: 1650, name: "Barcelona" },
    city: { id: 1737, name: "Barcelona" },
    district: { id: 1799, name: "Sants-Montjuïc" },
    neighborhood: { id: 1801, name: "Hostafrancs" },
  },
  fullAddress: "Carrer Del Consell De Cent 1,  Barcelona",
  floor: "2",
  door: "3",
  mainPhoto: "link",
  isVisible: false,
  propertyStatus: PropertyStatus.CAPTURED,
  lastPropertyStatusChangedAt: "2022-06-15T16:02:00.000000Z",
  activeRentalLeasings: [],
};

const defaultProps = {
  property,
};

const component = (data = {}, props = defaultProps) => {
  return myRentalsShallowMount(OwnerPropertyCard, {
    data,
    props,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("OwnerPropertyCard", () => {
  testMyRentalsShallowMount(OwnerPropertyCard, { props: defaultProps });

  describe("Computed", () => {
    describe("propertyLink", () => {
      it("Should return correct dashboard property internal link", () => {
        // given
        const card = component();
        // when
        const expectedResult = {
          name: "property",
          params: {
            propertyUuid: defaultProps.property.propertyUuid,
          },
        };

        // then
        expect(card.vm.propertyLink).toEqual(expectedResult);
      });
    });
  });

  describe("Mounted", () => {
    it("should call propertyStatus api with correct params", () => {
      // given
      spyOn(api.propertyStatusCategory(), "get");
      // when
      component();
      // then
      expect(api.propertyStatusCategory().get).toHaveBeenCalledWith(
        property.propertyUuid
      );
    });
    it("should set isCardClickable to true if the propertyStatus is not old myh", async () => {
      // given
      spyOn(api.propertyStatusCategory(), "get").and.returnValue({
        propertyStatusCategory: "RE published",
      });
      // when
      const wrapper = await component();
      // then
      expect(wrapper.vm.isCardClickable).toBe(true);
    });
    it("should set isCardClickable to true if the propertyStatus is old myh", async () => {
      // given
      spyOn(api.propertyStatusCategory(), "get").and.returnValue({
        propertyStatusCategory: "old myh",
      });
      // when
      const wrapper = await component();
      // then
      expect(wrapper.vm.isCardClickable).toBe(false);
    });
    it("set showCards to true after mounting the component", async () => {
      // given
      spyOn(api.propertyStatusCategory(), "get");
      // when
      const wrapper = await component();
      // then
      expect(wrapper.vm.showCards).toBe(true);
    });
  });
});
